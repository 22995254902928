import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

type Props = {
  children: React.ReactNode;
};

const ONE_MINUTE = 60 * 1000;

const fetchVersion = async () => {
  const response = await fetch('/json/version.json', { cache: 'no-cache' });
  if (!response.ok) {
    return null;
  }
  return response.json();
};

const VersionTracker = ({ children }: Props) => {
  const version = useRef<string | null>(null);

  const { data } = useQuery('_VERSION', fetchVersion, { refetchInterval: ONE_MINUTE });
  const newVersion = data?.buildDate ?? null;

  useEffect(() => {
    if (newVersion !== null) {
      if (version.current === null) {
        version.current = newVersion;
      } else if (version.current !== newVersion) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }, [newVersion]);

  return <>{children}</>;
};

export default VersionTracker;
