import getCacheKeyDate from '../utils/getCacheKeyDate';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';
import { Api } from '../settings';

type Options = {
  refetch?: boolean;
  useInferenceApi?: boolean;
};

const ONE_MINUTE = 60000;
export const buildQueryKey = (startDateValue: string, endDateValue: string) => [
  'recommendations',
  startDateValue,
  endDateValue
];

const useRecommendationsQuery = (startDate: Date, endDate: Date, options?: Options) => {
  const refetchInterval = options?.refetch ? ONE_MINUTE : false;
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  const useInferenceApi = !!options?.useInferenceApi;
  const api = useInferenceApi ? Api.inference : Api.serve;
  return useAuthorisedQuery(
    buildQueryKey(getCacheKeyDate(startDate), getCacheKeyDate(endDate)),
    `recommendation/?start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {
      refetchInterval
    },
    api
  );
};

export default useRecommendationsQuery;
