[
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T18:15:00+00:00",
    "value": 1.7867,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T18:30:00+00:00",
    "value": 1.7877,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T18:45:00+00:00",
    "value": 1.7915,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T19:00:00+00:00",
    "value": 1.8032,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T19:15:00+00:00",
    "value": 1.814,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T19:30:00+00:00",
    "value": 1.8159,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T19:45:00+00:00",
    "value": 1.8167,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T20:00:00+00:00",
    "value": 1.9316,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T20:15:00+00:00",
    "value": 1.9324,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T20:30:00+00:00",
    "value": 1.9312,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T20:45:00+00:00",
    "value": 1.9238,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T21:00:00+00:00",
    "value": 1.9233,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T21:15:00+00:00",
    "value": 1.9016,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T21:30:00+00:00",
    "value": 1.9016,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T21:45:00+00:00",
    "value": 1.8998,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T22:00:00+00:00",
    "value": 1.9002,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T22:15:00+00:00",
    "value": 1.8988,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T22:30:00+00:00",
    "value": 1.8971,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T22:45:00+00:00",
    "value": 1.8906,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T23:00:00+00:00",
    "value": 1.8822,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T23:15:00+00:00",
    "value": 1.8764,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T23:30:00+00:00",
    "value": 1.8733,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-25T23:45:00+00:00",
    "value": 1.8704,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T00:00:00+00:00",
    "value": 1.6528,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T00:15:00+00:00",
    "value": 1.6543,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T00:30:00+00:00",
    "value": 1.6569,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T00:45:00+00:00",
    "value": 1.6587,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T01:00:00+00:00",
    "value": 1.6606,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T01:15:00+00:00",
    "value": 1.6639,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T01:30:00+00:00",
    "value": 1.6652,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T01:45:00+00:00",
    "value": 1.6626,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T02:00:00+00:00",
    "value": 1.7418,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T02:15:00+00:00",
    "value": 1.7368,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T02:30:00+00:00",
    "value": 1.7295,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T02:45:00+00:00",
    "value": 1.7204,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T03:00:00+00:00",
    "value": 1.8631,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T03:15:00+00:00",
    "value": 1.8551,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T03:30:00+00:00",
    "value": 1.8479,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T03:45:00+00:00",
    "value": 1.8413,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T04:00:00+00:00",
    "value": 1.8327,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T04:15:00+00:00",
    "value": 1.8227,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T04:30:00+00:00",
    "value": 1.8139,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T04:45:00+00:00",
    "value": 1.8047,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T05:00:00+00:00",
    "value": 1.7946,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T05:15:00+00:00",
    "value": 1.7843,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T05:30:00+00:00",
    "value": 1.7731,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T05:45:00+00:00",
    "value": 1.7646,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T06:00:00+00:00",
    "value": 1.8632,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T06:15:00+00:00",
    "value": 1.8587,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T06:30:00+00:00",
    "value": 1.8523,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T06:45:00+00:00",
    "value": 1.846,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T07:00:00+00:00",
    "value": 1.8409,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T07:15:00+00:00",
    "value": 1.8342,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T07:30:00+00:00",
    "value": 1.8201,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T07:45:00+00:00",
    "value": 1.8146,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T08:00:00+00:00",
    "value": 1.8048,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T08:15:00+00:00",
    "value": 1.7963,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T08:30:00+00:00",
    "value": 1.7855,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T08:45:00+00:00",
    "value": 1.7722,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T09:00:00+00:00",
    "value": 1.7586,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T09:15:00+00:00",
    "value": 1.7463,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T09:30:00+00:00",
    "value": 1.7363,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T09:45:00+00:00",
    "value": 1.746,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T10:00:00+00:00",
    "value": 1.7399,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T10:15:00+00:00",
    "value": 1.7301,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T10:30:00+00:00",
    "value": 1.7231,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T10:45:00+00:00",
    "value": 1.7207,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T11:00:00+00:00",
    "value": 1.7157,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T11:15:00+00:00",
    "value": 1.7076,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T11:30:00+00:00",
    "value": 1.6989,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T11:45:00+00:00",
    "value": 1.7775,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T12:00:00+00:00",
    "value": 1.771,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T12:15:00+00:00",
    "value": 1.761,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T12:30:00+00:00",
    "value": 1.7493,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T12:45:00+00:00",
    "value": 1.7431,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T13:00:00+00:00",
    "value": 1.7392,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T13:15:00+00:00",
    "value": 1.7371,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T13:30:00+00:00",
    "value": 1.7355,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T13:45:00+00:00",
    "value": 1.8812,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T14:00:00+00:00",
    "value": 1.8788,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T14:15:00+00:00",
    "value": 1.878,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T14:30:00+00:00",
    "value": 1.878,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T14:45:00+00:00",
    "value": 1.8753,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T15:00:00+00:00",
    "value": 1.8678,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T15:15:00+00:00",
    "value": 1.8598,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T15:30:00+00:00",
    "value": 1.8505,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T15:45:00+00:00",
    "value": 1.47,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T16:00:00+00:00",
    "value": 1.4597,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T16:15:00+00:00",
    "value": 1.449,
    "error": null
  },
  {
    "feature": "s_c_sol_fcao_soft",
    "timestamp": "2024-09-26T16:30:00+00:00",
    "value": 1.4369,
    "error": null
  }
]
