import { subHours } from 'date-fns';
import useLatestRecommendationsQuery from '../../../../serviceQueries/useLatestRecommendationsQuery';
import { FeatureName, Recommendation } from '../../../../types';
import useNow from '../../../../useNow';
import useRecommendationDataFeatureConfigs from '../../../../utils/useRecommendationDataFeatureConfigs';

type Response = {
  data: Recommendation[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
};

// eslint-disable-next-line
const buildRecommendation = (datum: any): Recommendation => ({
  timestamp: new Date(datum.timestamp),
  feature: datum.feature as FeatureName,
  value: datum.value === null ? 0 : datum.value,
  error: datum.error ?? null
});

const useLatestRecommendations = (): Response => {
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const now = useNow();
  const startDate = subHours(now, 6);
  const {
    data: recommendationsData = [],
    isLoading,
    isFetching,
    isError
  } = useLatestRecommendationsQuery(startDate, now, { useInferenceApi: true });
  if (isLoading) {
    return {
      data: [],
      isLoading,
      isFetching,
      isError
    };
  }

  const recommendationFeatureNames = recommendationDataFeatureConfigs.map(
    (featureConfig) => featureConfig.feature.name
  );

  const recommendations = recommendationsData
    // eslint-disable-next-line
    .map((datum: any) => buildRecommendation(datum))
    .filter(({ feature }: Recommendation) => recommendationFeatureNames.includes(feature))
    .reverse();

  return {
    data: recommendations,
    isLoading,
    isFetching,
    isError
  };
};

export default useLatestRecommendations;
