import { addHours, subHours } from 'date-fns';
import useNow from '../../../../useNow';
import { useHistoricalPlantData, useHistoricalSoftSensors, useUnit } from '../../../../utils';
import ChartTitle from '../ChartTitle';
import ChartContainer from './ChartContainer';
import { QualityChartConfig } from '../../schema';
import { LocalisedLabel } from '../../../../lib';
import ChartLoadingMessage from '../ChartLoadingMessage';
import ChartErrorMessage from '../ChartErrorMessage';

type Props = {
  config: QualityChartConfig;
};
const QualitySection = ({ config }: Props) => {
  const now = useNow();
  const startDate = subHours(now, 24);
  const endDate = addHours(now, 6);
  const { label, modelled, measured } = config;
  const {
    data: measuredData,
    isLoading: isMeasuredDataLoading,
    isError: isMeasuredDataError
  } = useHistoricalPlantData([measured.name], startDate, endDate, 15, true);
  const {
    data: modelledData,
    isLoading: isModelledDataLoading,
    isError: isModelledDataError
  } = useHistoricalSoftSensors([modelled.name], startDate, endDate, true);
  const { data: unit, isLoading: isLoadingUnit, isError: isUnitError } = useUnit(measured.name);
  const isLoading = isMeasuredDataLoading || isModelledDataLoading || isLoadingUnit;
  const isError = isMeasuredDataError || isModelledDataError || isUnitError;
  return (
    <section>
      <ChartTitle>
        <>
          <LocalisedLabel>{label}</LocalisedLabel> ({unit})
        </>
      </ChartTitle>
      {isLoading && <ChartLoadingMessage />}
      {isError && <ChartErrorMessage />}
      {!isLoading && !isError && (
        <ChartContainer
          modelledData={modelledData}
          measuredData={measuredData}
          startDate={startDate}
          endDate={endDate}
          config={config}
          unit={unit}
        />
      )}
    </section>
  );
};

export default QualitySection;
