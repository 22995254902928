import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import DirectionIcon from './DirectionIcon';
import usePlantParameters, { Direction } from './usePlantParameters';
import { EzerTheme } from '../../../../../EzerThemeProvider';
import { Unit } from '../../../../../lib';
import { useFulfillmentRecorder, round } from '../../../../../utils';
import { New, NotApplicable } from '../../../../../messages';

type Props = {
  classname?: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  table: {
    display: 'block',
    marginTop: spacing(2),
    minHeight: spacing(23.5)
  },
  thead: {
    display: 'block'
  },
  row: {
    display: 'flex',
    marginLeft: spacing(1),
    marginRight: spacing(1),
    columnGap: spacing(1),
    marginTop: spacing(1)
  },
  experimentalRow: {
    opacity: 0.5
  },
  tbody: {
    display: 'block',
    maxHeight: spacing(12),
    overflow: 'hidden'
  },
  cell: {
    fontSize: spacing(2),
    lineHeight: `${spacing(2)}px`,
    height: spacing(5),
    textAlign: 'left',
    paddingTop: spacing(1.5),
    paddingLeft: spacing(1.25),
    paddingRight: spacing(1.25)
  },
  bigCell: {
    flex: 1
  },
  mediumCell: {
    width: spacing(18)
  },
  smallCell: {
    width: spacing(14)
  },
  bodyCell: {
    backgroundColor: palette.lightCarbon
  },
  iconCell: {
    position: 'relative',
    paddingLeft: spacing(6)
  },
  directionIcon: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}));
const ParameterTable = ({ classname = '' }: Props) => {
  const styles = useStyles();
  const plantParameters = usePlantParameters();
  const { setRecommendationMetric } = useFulfillmentRecorder();
  plantParameters.forEach((plantParameter) => {
    setRecommendationMetric({
      feature: plantParameter.name,
      label: plantParameter.label.default,
      isFulfilled: true,
      value: plantParameter.recommendedValue
    });
  });

  return (
    <table className={classNames(styles.table, classname)}>
      <thead className={styles.thead}>
        <tr className={styles.row}>
          <th className={classNames(styles.cell, styles.bigCell)}>
            <FormattedMessage defaultMessage="Plant Parameter" id="page.today.recommendation.header.setPoint" />
          </th>
          <th className={classNames(styles.cell, styles.smallCell)}>
            <FormattedMessage defaultMessage="Current" id="page.today.recommendation.header.current" />
          </th>
          <th className={classNames(styles.cell, styles.mediumCell)}>
            <FormattedMessage defaultMessage="Direction" id="page.today.recommendation.header.direction" />
          </th>
          <th className={classNames(styles.cell, styles.smallCell)}>
            <New />
          </th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {plantParameters.map(({ name, label, direction, recommendedValue, currentValue, unit, isExperimental }) => (
          <tr key={name} className={classNames(styles.row, { [styles.experimentalRow]: isExperimental })}>
            <td className={classNames(styles.cell, styles.bigCell, styles.bodyCell)}>{label.default}</td>
            <td className={classNames(styles.cell, styles.smallCell, styles.bodyCell)}>
              {currentValue !== undefined && (
                <>
                  {currentValue} <Unit>{unit}</Unit>
                </>
              )}
            </td>
            <td className={classNames(styles.cell, styles.mediumCell, styles.bodyCell, styles.iconCell)}>
              <DirectionIcon direction={direction} className={styles.directionIcon} />
              {direction === Direction.nochange && <>No change</>}
              {direction === Direction.increase && <>Increase to</>}
              {direction === Direction.decrease && <>Decrease to</>}
            </td>
            <td className={classNames(styles.cell, styles.smallCell, styles.bodyCell)}>
              {recommendedValue !== undefined && (
                <>
                  {round(recommendedValue, 2)} <Unit>{unit}</Unit>
                </>
              )}
              {recommendedValue === undefined && <NotApplicable />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ParameterTable;
