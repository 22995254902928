import useAuthorisedQuery from './useAuthorisedQuery';
import { getUtcDate } from '../utils';
import { Api } from '../settings';

const HALF_MINUTE = 30 * 1000;

export const CACHE_KEY = ['latest-recommendations', 'limit-2'];

type Options = {
  useInferenceApi?: boolean;
};

const useLatestRecommendationsQuery = (startDate: Date, endDate: Date, options?: Options) => {
  const useInferenceApi = !!options?.useInferenceApi;
  const api = useInferenceApi ? Api.inference : Api.serve;

  return useAuthorisedQuery(
    [...CACHE_KEY, `api-${api}`],
    `recommendation/?start_datetime=${getUtcDate(startDate)}&end_datetime=${getUtcDate(endDate)}&limit=2`,
    {
      refetchInterval: HALF_MINUTE
    },
    api
  );
};
export default useLatestRecommendationsQuery;
