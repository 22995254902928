/* eslint-disable @typescript-eslint/no-explicit-any */
import { subHours } from 'date-fns';
import { FeatureConfig } from '../../../../../Config/sharedSchemas';
import useHistoricalPlantDataQuery from '../../../../../serviceQueries/useHistoricalPlantDataQuery';
import { Recommendation, FeatureName } from '../../../../../types';
import useNow from '../../../../../useNow';
import { useMetadata } from '../../../../../utils';
import { Metadata } from '../../../../../utils/useMetadata';
import useRecommendationDataFeatureConfigs from '../../../../../utils/useRecommendationDataFeatureConfigs';

type TargetData = {
  value: number;
  unit: string;
};

type Response = {
  data: TargetData | null;
  isLoading: boolean;
};

const getTarget = (recommendation: Recommendation, recommendationDataFeatureConfigs: FeatureConfig[]): FeatureName => {
  const config = recommendationDataFeatureConfigs.find(
    (featureConfig) => featureConfig.feature.name === recommendation.feature
  );
  return config?.target?.name ?? recommendation.feature;
};

const makeTargetData = (
  featureName: FeatureName,
  recommendation: Recommendation,
  plantData: any,
  metadata: Metadata
): TargetData => {
  const matchingPlantDataItem = plantData.items.find(
    (dataItem: any) => new Date(dataItem.timestamp).getTime() <= recommendation?.timestamp.getTime()
  );
  return {
    value: matchingPlantDataItem.data[featureName].value,
    unit: metadata[featureName]?.unit ?? ''
  };
};

const useTargetData = (recommendation: Recommendation | null): Response => {
  const now = useNow();
  const startDate = subHours(now, 2);
  const endDate = recommendation === null ? now : recommendation.timestamp;
  const recommendationDataFeatureConfigs = useRecommendationDataFeatureConfigs();
  const featureNames = recommendation === null ? [] : [getTarget(recommendation, recommendationDataFeatureConfigs)];
  const { data: plantData, isLoading: isLoadingPlantData } = useHistoricalPlantDataQuery(
    featureNames,
    startDate,
    endDate,
    15,
    {
      disable: featureNames.length === 0
    }
  );
  const { data: metadata, isLoading: isLoadingMetadata } = useMetadata(featureNames);
  return {
    data:
      recommendation === null || plantData === undefined
        ? null
        : makeTargetData(featureNames[0], recommendation, plantData, metadata),
    isLoading: isLoadingPlantData || isLoadingMetadata
  };
};

export default useTargetData;
