import { ObjectOptions, Static, Type } from '@sinclair/typebox';
import { FeatureName } from '../types';
import { selectableEnum } from './utils';

export const LabelSchema = (options: ObjectOptions) =>
  Type.Object(
    {
      default: Type.String({ title: 'Default', description: 'The default label' }),
      id: Type.Optional(Type.String({ title: 'ID', description: 'The ID of the label' }))
    },
    options
  );

export const languageSchema = Type.Union(
  [
    Type.Literal('cs'),
    Type.Literal('en'),
    Type.Literal('es'),
    Type.Literal('pt-br'),
    Type.Literal('pt-pt'),
    Type.Literal('tr')
  ],
  {
    title: 'Language'
  }
);
export type Language = Static<typeof languageSchema>;

export const timezoneSchema = Type.Union(
  [
    Type.Literal('UTC'),
    Type.Literal('Asia/Bangkok'),
    Type.Literal('America/Cuiaba'),
    Type.Literal('America/Sao_Paulo'),
    Type.Literal('Europe/Istanbul'),
    Type.Literal('Europe/London'),
    Type.Literal('Europe/Madrid'),
    Type.Literal('Europe/Prague')
  ],
  {
    title: 'Timezone',
    description: 'The timezone of the plant'
  }
);
export type Timezone = Static<typeof timezoneSchema>;

// Note, if changing this make sure the KPI endpoints under src/serve/ still
// work as they depend on this structure.
export const kpiFeatureConfigSchema = Type.Object({
  name: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the feature'
  }),
  lowerBound: Type.Optional(Type.Number({ title: 'Lower Bound', description: 'The lower bound' })),
  upperBound: Type.Optional(Type.Number({ title: 'Upper Bound', description: 'The upper bound' })),
  baseline: Type.Optional(Type.Number({ title: 'Baseline', description: 'The baseline' })),
  yMax: Type.Optional(Type.Number({ title: 'Y Max', description: 'The max value for the Y axis' }))
});
export type KPIFeatureConfig = Static<typeof kpiFeatureConfigSchema>;

export const featureSchema = Type.Object({
  name: selectableEnum(FeatureName, {
    title: 'Feature Name',
    description: 'The name of the feature'
  }),
  label: LabelSchema({ title: 'Label', description: 'The label for the feature' })
});

export const featureConfigSchema = Type.Object(
  {
    defaultColour: Type.String({ title: 'Default Colour', description: 'The default colour' }),
    feature: featureSchema,
    to: Type.Optional(Type.String({ title: 'To', description: 'The to value' })),
    target: Type.Optional(featureSchema)
  },
  {
    title: 'Feature Config',
    description: 'The configuration for the feature'
  }
);
export type FeatureConfig = Static<typeof featureConfigSchema>;
