import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import { KPIFeatureConfig } from '../../useCustomer';
import { getLocalDateAndTime, useTimezone } from '../../utils';
import ChartTooltip from '../ChartTooltip';
import useItemsListData, { ItemsListDataPayload } from './useItemsListData';

interface Props extends TooltipProps<string, string> {
  unit: string;
  featureConfig: KPIFeatureConfig;
}

type TooltipLabelProps = {
  label: number;
};

const TooltipLabel = ({ label }: TooltipLabelProps) => {
  const timezone = useTimezone();
  const convertToTime = (timestamp: number) => (timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : label);
  return (
    <FormattedMessage
      defaultMessage="Date: {label}"
      id="chart.quality.tooltip"
      values={{ label: convertToTime(label) }}
    />
  );
};

const QualityTooltipContent = ({ label, payload = [], unit, featureConfig }: Props) => {
  const itemListData = useItemsListData(payload as ItemsListDataPayload, featureConfig, unit);
  return <ChartTooltip label={<TooltipLabel label={label} />} itemListData={itemListData} />;
};

export default QualityTooltipContent;
